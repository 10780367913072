.header {
    position: relative;
    background: #efefef;
    z-index: 11;
    width: 100%;
    .logo {
        margin: 0 auto;
        transition: .5s ease-in-out;
    }
    &-bottom {
        background: #efefef;
        position: relative;
        &.fixed-header {
            position: fixed;
            top:0; 
            left:0;
            width: 100%;
        }
    }
    .socials {
        float: left;
        margin: 45px 0px 0px 40px;
        .icon {
            position: relative;
            width: 25px;
            height: 25px;
            float: left;
            margin: 0 20px;
            transition: .5s ease-in-out;
            cursor: pointer;
            &.facebook {
                background: url(../img/facebook.svg) no-repeat;
                background-size: 100% 100%;
            }
            &.twitter {
                background: url(../img/twitter.svg) no-repeat;
                background-size: 100% 100%;
            }
            &:hover {
                transform: rotateY(360deg);
            }
        }
        p {
            display: inline-block;
        }
        a {
            text-decoration: none;
        }
    }
    .phone-number {
        display: inline-block;
        float: right;
        position: relative;
        bottom: -19px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f97e31+41,c9633b+100 */
        background: #f97e31;
        /* Old browsers */
        background: -moz-linear-gradient(top, #f97e31 41%, #c9633b 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #f97e31 41%, #c9633b 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #f97e31 41%, #c9633b 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f97e31', endColorstr='#c9633b', GradientType=0);
        /* IE6-9 */
        border-radius: 10px 10px 0px 0px;
        padding: 10px;
        border: 1px solid #febb64;
        padding: 6px 38px;
        transition: .5s ease-in-out;
        &:hover {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#25a3da+0,272d69+100 */
            background: #25a3da;
            /* Old browsers */
            background: -moz-linear-gradient(top, #25a3da 0%, #272d69 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #25a3da 0%, #272d69 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #25a3da 0%, #272d69 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25a3da', endColorstr='#272d69', GradientType=0);
            /* IE6-9 */
            border: 1px solid #272967;
        }
        a {
            font-size: 28px;
            font-weight: 400;
            color: $white-color;
            text-decoration: none;
        }
        p {
            color: $white-color;
            font-size: 18px;
            font-weight: 500;
            margin: 0;
        }
    }
    .main-menu {
        float: left;
        margin-top: 28px;
        >ul {
            padding: 0;
            margin: 0;
            >li {
                display: inline-block;
                list-style: none;
                vertical-align: middle;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px transparent;
                position: relative;
                margin-left: 20px;
                padding: 20px 0;
                &.current-menu-item a {
                    color: $main-accent-color;
                }
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    left: 50%;
                    right: 50%;
                    bottom: 15px;
                    background: $main-accent-color;
                    height: 2px;
                    -webkit-transition-property: left, right;
                    transition-property: left, right;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover:before,
                &:focus:before,
                &:active:before,
                &.current-menu-item:before {
                    left: 0;
                    right: 0;
                    color: $main-accent-color;
                }
                >.sub-menu {
                    display: none;
                    position: absolute;
                    top: 70px;
                    min-width: 280px;
                    background: #fff;
                    z-index: 666;
                    padding: 10px 0px;
                    left: 0;
                    li {
                        list-style-type: none;
                        text-align: left;
                        padding: 5px 25px 5px;
                        ;
                        font-size: 16px;
                        a {
                            color: #4d4d4d;
                            text-transform: none;
                            padding: 0;
                        }
                        &:hover a {
                            color: $main-accent-color;
                        }
                    }
                }
                &:hover {
                    .sub-menu {
                        display: block;
                    }
                }
                a {
                    text-decoration: none;
                    color: $dark-blue-color;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 30px;
                    text-transform: uppercase;
                    transition: .3s ease-in-out;
                    &:hover {
                        color: $main-accent-color;
                    }
                }
            }
        }
        &.mobile-menu {
            display: none;
        }
    }
    .hamburger {
        outline: none;
        padding: 5px 0;
        margin-top: 20px;
        float: right;
        &-inner,
        &-inner:after,
        &-inner:before {
            background-color: $main-accent-color;
        }
    }
}

.mobile-menu {
    display: none;
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
    ul {
        padding: 0;
        >li {
            display: block;
            text-align: right;
            float: right;
            width: 100%;
            padding: 0;
            border: none;
            &:before {
                display: none;
            }
            a {
                text-decoration: none;
                display: block;
                padding: 10px;
                text-transform: none;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .header {
        .search-form {
            display: none;
        }
        .logo {
            padding-bottom: 20px;
        }
        .phone-number {
            display: none;
        }
        .socials {
            display: none;
        }
        .main-menu {
            display: none;
            ul li {
                display: block;
                position: relative;
                padding: 0;
                &.menu-item-has-children {
                    &:before {
                        position: absolute;
                        left: 0;
                        top: 20px;
                        display: inline-block;
                        content: "";
                        width: 9px;
                        height: 6px;
                        background: url(../img/menu-arr.png) no-repeat center;
                        transform: rotate(90deg);
                        transition: .3s ease-in-out;
                    }
                    &:hover:before {
                        transform: rotate(0deg);
                    }
                }
            }
        }
        .mobile-menu>ul>li>.sub-menu {
            position: relative;
            top: 0;
            min-width: 350px;
            border-top: none;
            background: #0f114a;
            z-index: 11;
            padding: 0;
            right: 0;
            li {
                text-align: right;
                padding: 5px 25px 5px;
                a {
                    padding: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .header {
        .top-menu {
            display: none;
        }
        .mobile-menu>ul>li>.sub-menu {
            min-width: 1px;
        }
    }
}