.footer {
    &--top {
        background-color: #efefef;
        padding: 25px 0 10px;
        .company-info {
            margin-top: 20px;
        }
        p {
            color: $main-text-color;
            font-size: 14px;
            line-height: 10px;
            span {
                margin-left: 60px;
            }
        }
        a {
            color: $main-text-color;
        }
        &__nav {
            float: left;
            padding: 0;
            border-right: 2px solid rgba(96, 140, 187, 0.5);
            border-left: 2px solid rgba(96, 140, 187, 0.5);
            ul {
                float: left;
                padding: 0;
                margin: 40px;
            li {
                list-style: none;
                line-height: 24px;
                a {
                    color: $black-color;
                }
            }
        }
        }
        .partner-logo {
                top: 20px;
                position: relative;
        }
    }
    &--bottom {
        background-color: $main-blue-color;
        padding: 25px 0;
        p {
            color: $white-color;
            font-size: 13px;
            font-weight: 400;
            float: left;
            padding: 0;
            margin: 0;
        }
        nav ul {
            list-style: none;
            float: left;
            padding: 0;
            margin: 0;
            li {
                border-left: 1px solid #fff;
                padding: 0 20px;
                margin: 0 20px;
                a {
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
        a {
            color: $white-color;
        }
    }
}
@media screen and (max-width: 767px) {
        .footer--top {
        text-align: center;
        .logo,
        .partner-logo {
            margin: 0 auto 30px auto;
        }
        &__nav {
            float: none;
            border-right: none;
            border-left: none;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            margin-top: 25px;
        }
    }
    .footer--bottom {
        p {
            text-align: center;
            width: 100%;
        }
        nav ul {
            margin: 0 auto;
            width: 100%;
            li {
                border-left: 0;
                text-align: center;
                margin: 10px auto;
                display: block;
            }
        }
    }
}
