$main-accent-color: #FB7D33;
$main-text-color: #4a4a4a;
$main-blue-color: #5479a5;
$dark-blue-color: #2d3451;
$white-color: #ffffff;
$black-color: #000000;

.large-orange {
	color: $main-accent-color;
}
.large-blue {
	color: $main-blue-color;
}