// Top Image Section
.section-top {
    z-index: 10;
    position: relative;
    // padding-top: 244px;
    &.inner img {
        padding: 15px;
        width: 100%;
        max-height: 320px;
        object-fit: cover;
    }
    .main-slider {
        .slide-item {
            position: relative;
            max-height: 800px;
            padding: 15px;
            img {
                max-height: 650px;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
            .container {
                position: relative;
            }
            .slide-cta {
                position: absolute;
                top: 200px;
                z-index: 10;
                background-color: rgba(80,113,156,.5);
                padding: 30px 60px;
                border-radius: 15px;
                p {
                    font-size: 34px;
                    text-align: center;
                    color: $white-color;
                }
                .btn-holder {
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}
.btn-orange {
    display: inline-block;
    padding: 10px 40px;
    font-size: 22px;
    color: $white-color;
    text-transform: uppercase;
    border-radius: 15px;    
    margin: 15px 0 25px;
    border: 1px solid #febb64;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f97e31+41,c9633b+100 */
    background: #f97e31; /* Old browsers */
    background: -moz-linear-gradient(top, #f97e31 41%, #c9633b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f97e31 41%,#c9633b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f97e31 41%,#c9633b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f97e31', endColorstr='#c9633b',GradientType=0 ); /* IE6-9 */
    border-image-source: linear-gradient(#b6522b, #febb64);
}
@media screen and (max-width: 1500px) {
    .section-top .main-slider .slide-item .slide-cta {
        top: 50px;
        p {
            font-size: 28px;
        }
    }

}
@media screen and (max-width: 1200px) {
    .section-top {
        .main-slider {
            .slide-item {
                img {
                    height: 400px;
                }
            }
        }
    }
    .footer--top__nav ul {
        margin: 0 40px;
    }
}
@media screen and (max-width: 767px) {
    .section-top {
        .main-slider {
            .slide-item {
                padding: 0;
                img {
                    height: 300px;
                }
                .slide-cta {
                    max-width: 100%;
                    top: 20px;
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
