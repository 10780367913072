h1 {
	color: $main-blue-color;
	font-size: 44px;
	text-transform: uppercase;
	font-weight: 400;
}
h2 {
	color: $main-accent-color;
	font-size: 30px;
	font-weight: 400;
}
.ver-line {
	border-right: 1px solid #e4e5e6;
}
.section-main {
	padding: 20px 0 40px;
	p {
		font-size: 15px;
		color: $main-text-color;
	}
	h2 {
		padding-bottom: 20px;
	}
}
//CTAs section
.slick-initialized .slick-slide {
outline: none;
}
.section-cta {
.section-title {
	font-size: 44px;
	color: $main-text-color;
	padding: 20px 0;
	span {
		font-weight: 400;
	}
}
.cta-slider {
	padding-bottom: 40px;
	width: 1440px;
	margin: 0 auto;
	.slick-arrow {
		position: absolute;
		top: 50%;
		font-size: 0;
		border: none;
		background: none;
		margin-top: -36px;
		width: 40px;
		height: 73px;
		outline: none;
		transition: all ease-in-out 0.3s;
		&.slick-next {
			background: url(../img/right-arr.png) no-repeat center;
			right: -50px;
		}
		&.slick-prev {
			background: url(../img/left-arr.png) no-repeat center;
			left: -50px;
		}
	}
	.cta-item {
		box-sizing: border-box;
		margin: 20px;
		cursor: pointer;
		float: left;
		transition: .3s ease-in-out;
		display: block;
		position: relative;
		outline: none;
		img {
			border: 2px solid #6792c9;
			border-radius: 20px;
			width: 100%;
			object-fit: cover;
		}
		&:hover {
			opacity: 0.8;
		}
		.cta-title {
			font-size: 26px;
			z-index: 666;
			font-weight: 400;
			line-height: 32px;
			color: $main-text-color;
			text-align: center;
			position: absolute;
			padding: 30px;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 10;
		}
	}
}
}
//Commercial Assistance and Services Section 
.section-learn-more {
width: 100%;
min-height: 800px;
display: flex;
justify-content: center;
align-items: center;
vertical-align: middle;
.blue-cta {
	background: #608cbb;
	box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.7);
	border-radius: 10px;
	padding: 35px;
	position: relative;
	max-width: 550px;
	.cta-title {
		font-size: 30px;
		text-transform: uppercase;
		font-weight: 400;
		line-height: 32px;
	}
	p {
		color: $white-color;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
	}
}
}
.btn-white {
	background: $white-color;
	font-size: 26px;
	color: #f9930f;
	font-weight: 400;
	text-transform: uppercase;
	padding: 5px 60px 5px 30px;
	text-align: left;
	border-radius: 10px;
	margin-top: 50px;
}
.section-assistance {
	background: url(../img/commercial-assistance.jpg) bottom center no-repeat;
	background-size: cover;
.assistance-img {
	position: absolute;
	bottom: -30px;
	width: 320px;
	left: 240px;
}
}
.section-services {
	background: url(../img/residential-services.jpg) bottom center no-repeat;
	background-size: cover;
.services-img {
	position: absolute;
	bottom: -15px;
	width: 230px;
	left: -18px;
}
.btn-white {
	margin-left: 130px;
	padding: 5px 30px 5px 60px !important;
}
}

//Testimonials Page
.section-testimonials {
	background: url(../img/testimonials-bg.jpg) no-repeat;
	width: 100%;
	min-height: 600px;
	background-size: cover;
	.slick-arrow {
		position: absolute;
		top: 20%;
		font-size: 0;
		border: none;
		background: none;
		width: 40px;
		height: 73px;
		outline: none;
		transition: all ease-in-out 0.3s;
		&.slick-next {
			background: url(../img/right-arr.png) no-repeat center;
			right: -50px;
		}
		&.slick-prev {
			background: url(../img/left-arr.png) no-repeat center;
			left: -50px;
		}
	}
.testimonials-title {
	font-size: 44px;
	color: $main-blue-color;
	text-transform: uppercase;
	margin-top: 80px;
	font-weight: 400;
	&:after {
		content: '';
		display: block;
		height: 2px;
		background: rgba(74, 74, 74, 0.56);
		width: 375px;
		margin: 40px auto;
	}
}
.testimonial-item {
	font-size: 28px;
	color: $main-text-color;
	font-style: italic;
	font-weight: 400;
	line-height: 45px;
}
.testimonial-author {
	float: right;
	text-align: right;
	width: 100%;
	font-size: 28px;
	color: $main-text-color;
	font-style: italic;
	font-weight: 400;
	&:before {
		content: '-';
		display: inline-block;
		margin-right: 10px;
	}
}
}
//Blog
.blog-post {
&__img {
	img {
		display: block;
		max-width: 200px;
		height: 200px;
		object-fit: cover;
		margin-top: 25px;
	}
}
.btn-white {
	font-size: 14px;
	padding: 10px 20px;
}
}
.blog-post-content img {
	width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 0 30px 30px 0;
}

//Team section
.team-section {
	.team-members {
		display: flex;
	    justify-content: center;
	    flex-wrap: wrap;
	    text-align: center;
	.team-member {
		width: 25%;
	.member-photo {
		width: 265px;
	    height: 265px;
	    object-fit: cover;
	    border-radius: 20px;
	    box-shadow: 0px 0px 0px 4px #749bcd;
	}
	.member-name {
		margin-top: 20px;
		font-size: 20px;
	}
	.member-descr {
		font-size: 16px;
		margin-bottom: 40px;	
		p {
			margin: 0;
		}
	}
}
}
}
//Sidebar 
.widget-area {
	padding: 0 20px;
h3 {
	font-size: 30px;
	color: $main-accent-color;
	font-weight: 400;
	text-transform: uppercase;
	&:after {
		content: '';
		display: block;
		height: 6px;
		background: #5479a5;
		width: 100%;
		margin: 5px 0;
		padding: 0;
	}
}
a {
	color: #4a4a4a;
}
ul {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		&:before {
			content: '';
			display: inline-block;
			background: url(../img/check-mark.png) center center no-repeat;
			width: 22px;
			height: 22px;
			background-size: 20px;
			margin-right: 10px;
		}
	}
}
}


@media screen and (max-width: 1540px) {
.cta-slider {
	width: 80% !important;
	.cta-title {
		padding: 20px !important;
		font-size: 20px !important;
		line-height: 24px !important;
	}
}
}
@media screen and (max-width: 1200px) {
.section-main {
	h1 {
		font-size: 32px;
		line-height: 38px;
	}
	h2 {
		font-size: 24px;
	}
}
.section-cta .section-title,
.section-testimonials .testimonials-title {
	font-size: 34px;
}
.section-testimonials {
	min-height: 460px;
	.testimonial-item,
	.testimonial-author {
		font-size: 22px;
		line-height: 32px;
		width: 100%;
	}
}
.team-section .team-members .team-member {
		width: 33.3%;
	}
}
@media screen and (max-width: 992px) {
.team-section .team-members .team-member {
		width: 50%;
	}
}
@media screen and (max-width: 767px) {
.section-main {
	h1 {
		font-size: 28px;
	}
}
.section-assistance .assistance-img,
.section-services .services-img {
	display: none;
}
.section-learn-more .blue-cta .btn-white,
.section-services .btn-white {
	padding: 5px 50px !important;
	font-size: 22px;
	margin-top: 30px;
	margin-left: 0;
}
.section-learn-more {
	min-height: 650px;
	.blue-cta {
		margin: 30px;
	}
}
}
@media screen and (max-width: 600px) {
.team-section .team-members .team-member {
		width: 100%;
	}
}